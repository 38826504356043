import { IGrayFeatureEnabledMap } from './ReturnsSettings.ts';

import { Maybe } from '../common';

export type GetShopInfoPayload = {
  shopHostName?: string;
  orgId?: string;
};

export enum ExchangeRuleItemPriceComparison {
  Equal = 'equal',
  EqualOrLessThan = 'equal_or_less_than',
  LessThan = 'less_than',
}
// 后端 replace the same item 一直命名为 exchange
export enum ExchangeMode {
  SamePrice = 'same_price',
  DifferentPrice = 'allow_different_price',
  NotesOnly = 'use_notes_only',
}
// 后端 replace the same item 一直命名为 exchange
export enum ExchangeChargeMode {
  EvenReplace = 'even_replace',
  ChargeByRC = 'charge_by_rc',
  ChargeByOthers = 'charge_by_others',
}

export enum LogoPosition {
  MiddleLeft = 'middle_left',
  TopLeft = 'top_left',
  TopCenter = 'top_center',
}

export enum FontType {
  Default = 'default',
  Custom = 'custom',
}

export interface Shops {
  hero_image?: ShopAsset;
  logo_image?: ShopAsset;
  logo_position_on_large_screen: LogoPosition;
  policy_text: string;
  external_return_policy_page: boolean;
  policy_url: string;
  store_name: string;
  src: string;
  marketing_assets: MarketingAsset[];
  theme_color: string;
  favicon?: {
    src: string;
  };
  exchange_for_anything_extra_credit_require_minimum_refund_subtotal: string;
  menu_items?: MenuItem[];
  social_facebook?: string;
  social_instagram?: string;
  social_twitter?: string;
  terms_url?: string;
  privacy_url?: string;
  contact_url?: string;
  id: string;

  custom_hostname?: string;
  show_returns_page_powered_by: boolean;
  // [ 'en-US', 'es', 'pt-BR', 'de', 'fr', 'zh-Hans', 'ja-JP', 'it-IT', 'sv-SE', 'tr-TR', 'nb-NO', 'nl-NL', 'pl-PL', 'da-DK' ]
  languages: string[];
  // 'en-US'
  default_language: string;
  store_url: string;
  fonts: Font[];

  returns_page_primary_font: string;
  returns_page_body_font: string;

  allowed_returns_page_iframe_domains: string[] | null;
  applied_features: AppliedFeatures | null;
  google_analytics_tracking_id: string | null;
  hide_replace_summary_and_price: boolean;
  returns_page_status: 'published' | 'unpublished';
  returns_page_block_search_engine: boolean;
  // 转为内部接口后调整的字段
  bypass_single_resolution_selection_enabled: boolean;
  bypass_single_return_method_selection_enabled: boolean;
  // 替换原有的 user_uuid 和 username
  organization: {
    id: string;
    short_name: string;
  };
  // EFA 选商品方式
  exchange_for_anything_shopping_channel: EfaMethod | null;
  exchange_for_anything_active: boolean;
  checkout_gateway_active: PayMethod | null;
  exchange_for_anything_recommendation_active: boolean;
  exchange_active: boolean;
  exchange_mode: ExchangeMode;
  exchange_rule_item_price_difference_settlement: ExchangeChargeMode;
  exchange_rule_allow_add_notes: boolean;

  instant_exchange_charge_trigger_seconds: number;
  instant_exchange_charge_trigger_status: Maybe<InstantExchangeChargeTriggerStatus>;
  // 替换原有的 /rc/v4/shopper/setting/returns 接口
  order_lookup: {
    by_email_enabled: boolean;
    by_postal_code_enabled: boolean;
    by_email_enabled_updated_at: string;
    by_postal_code_enabled_updated_at: string;
  };
  exchange_rule_hide_unselectable_item: boolean;
  cost_of_return_charge_enabled: boolean;
  preserved_minimum_inventory_level_amount: number;
  preserved_minimum_inventory_level_enabled: boolean;
  // 替换原有的 /rc/v4/shopper/setting/shop 接口
  feature_flags: IGrayFeatureEnabledMap;

  // review 页面取 matching 到的 resolution 后,需要删除这些字段
  exchange_for_anything_name: string;
  exchange_for_anything_description: string;
  refund_to_store_credit_name: string;
  refund_to_store_credit_description: string;
  refund_to_original_payment_name: string;
  refund_to_original_payment_description: string;
  instant_refund_with_refundid_name: string;
  instant_refund_with_refundid_description: string;
  exchange_name: string;
  exchange_description: string;
  refund_to_original_payment_active: boolean;
  refund_to_store_credit_active: boolean;
}

export interface Font {
  type: FontType;
  name: string;
  url: string;
  in_use: boolean;
}

export interface ShopAsset {
  name: string;
  height: number;
  id: string;
  shop_id: string;
  size: string;
  src: string;
  type: 'hero_image' | 'logo_image';
  width: number;
}

export interface MarketingAsset {
  image_url: string;
  destination_url: string | null;
}

export interface MenuItem {
  name: string;
  url: string;
}

interface IAvailable {
  available: boolean;
}

export interface AppliedFeatures {
  app_proxy_iframe: IAvailable;
  returns_page: IAvailable;
}

export enum EfaMethod {
  inStore = 'in_store',
  inApp = 'in_app',
}

export enum PayMethod {
  shopify = 'shopify_checkout',
  stripe = 'stripe',
}

export enum InstantExchangeHoldPaymentAmountType {
  oneDollar = 'one_dollar',
  fullReturnValue = 'full_return_value',
}

export enum InstantExchangeChargeTriggerStatus {
  trackingInTransit = 'tracking_in_transit',
  trackingDelivered = 'tracking_delivered',
  returnReceived = 'return_received',
}

export const InstantExchangeChargeTriggerStatusMap = {
  [InstantExchangeChargeTriggerStatus.trackingInTransit]: 'in transit',
  [InstantExchangeChargeTriggerStatus.trackingDelivered]: 'delivered',
  [InstantExchangeChargeTriggerStatus.returnReceived]: 'marked as received',
};
